import { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import { DashboardScreenStyle } from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/styles/DashboardScreen.style"
import DashboardContent from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/DashboardContent"
import DashboardAside from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/DashboardAside"
import { useEnv } from "hooks"
import usePostMiddlewareStateMachineTypes from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareStateMachineTypes"
import logo_omnix from "assets/images/logo-omnix3.png"

const select_options = [
  // {
  //   value: "Tipo de envío",
  //   id: "SP,HD",
  // },
  {
    label: "Despacho a domicilio",
    value: "HD",
    id: "HD",
  },
  // {
  //   label: "Retiro en tienda",
  //   value: "SP",
  //   id: "SP",
  // },
]

const DashBordScreen = (props: any) => {
  const { displayHeaderRouteName, state_machine_states } = useContext(GlobalContext)
  const history = useHistory()
  const { permissions } = useEnv()
  const [loading, set_loading] = useState(true)
  const [select_value, select_dispatch] = useState<string>(select_options[0]["id"])
  const { post_middleware_state_machine_schema } = usePostMiddlewareStateMachineTypes({
    type: "ShippingGroup",
    shippingType: select_value,
  })

  const event_set_select = (value: any) => select_dispatch(value)

  const handleClickSearch = (e: any) => history.push(`/search/${e.optionFilter}/${e.query}`)

  useEffect(() => {
    displayHeaderRouteName.dispatch("Actividades y Despachos")
    set_loading(false) // remove
    // fetch_data()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    set_loading(true)
    const post_req = async () => {
      const res = await post_middleware_state_machine_schema()
      const res_states = res?.data?.message ? res?.data?.message[0]?.states : []
      localStorage.setItem("STATE_MACHINE", JSON.stringify(res_states))
      state_machine_states.dispatch(res_states)
      set_loading(false)
    }
    post_req()
    //eslint-disable-next-line
  }, [select_value])

  const template_dashboard = (
    <section className="dashboard">
      {permissions.get_permission("dashboard", "block-reports") && <DashboardAside />}
      <article className="dashboard__content">
        {permissions.get_permission("dashboard", "block-order-activity") && (
          <DashboardContent
            data={null}
            class_modifier={"main"}
            title={`Resumen de actividades - ${select_value === "HD" ? "Despachos" : "Retiro en tienda"}`}
            select_value={select_value}
            select_options={select_options}
            event_set_select={event_set_select}
            state_machine_states={state_machine_states?.state}
          />
        )}
      </article>
    </section>
  )

  return (
    <>
      {loading && <Loading defaultOpened={true} />}
      {permissions.get_permission("global", "block-search-dashboard") && (
        <SearchForm onClickSearch={handleClickSearch} />
      )}
      {template_dashboard}
      <div className="cont__order-home__footer">
        <p>Powered by</p>
        <img src={logo_omnix} alt="logo" className="logo_omnix" />
      </div>
      <style jsx>{DashboardScreenStyle}</style>
    </>
  )
}

export default DashBordScreen
